<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Spacer -->

            <!-- Invoice Client & Payment Details -->
                  <!--
            <b-card-body
              class="invoice-padding pt-0"
            >
       <b-row class="invoice-spacing">
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Invoice To:
                  </h6>

                    {{member.zh_name}} {{member.en_name}}
                    <template #list-header>
                      <li
                        v-b-toggle.sidebar-invoice-add-new-customer
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="16"
                        />
                        <span class="align-middle ml-25">Add New Customer</span>
                      </li>
                    </template>
                  </v-select>
                  <div
                    v-if="invoiceData.client"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ invoiceData.client.name }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.company }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.address }}, {{ invoiceData.client.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ invoiceData.client.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ invoiceData.client.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div>
                    <h6 class="mb-2">
                      Payment Details:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total Due:
                          </td>
                          <td><span class="font-weight-bold">$12,110.55</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Bank name:
                          </td>
                          <td>American Bank</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Country:
                          </td>
                          <td>United States</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            IBAN:
                          </td>
                          <td>ETD95476213874685</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            SWIFT code:
                          </td>
                          <td>BR91905</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          -->
            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          Item
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Qty
                        </b-col>
                          <b-col
                          cols="12"
                          lg="2"
                        >
                          Each Price
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Price
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="product_name"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="val => updateItemForm(index, val)"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Qty</label>
                          <!--<b-form-input
                            v-model="item.qty"
                            type="number"
                            class="mb-2"
                          />-->
                          <b-form-spinbutton
                              v-model="item.qty"
                              min="1"
                              max="100"
                            />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Each Price</label>
                          <p class="mb-1" v-if="item.itemTitle && item.itemTitle.open_price != 'Y'">
                            $ {{item.itemTitle.price}}
                          </p>
                          <b-form-input
                          v-model="item.itemTitle.price"
                            placeholder="Amount"
                            type="number"
                            class="mb-2"
                            v-if="item.itemTitle && item.itemTitle.open_price == 'Y'"
                          />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          <label class="d-inline d-lg-none">Price</label>
                          <p class="mb-1" v-if="item.itemTitle && item.qty > 0">
                          $ {{ (item.itemTitle.price * item.qty).toFixed(2) }}
                          </p>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="12"
                        >
                          <label class="d-inline d-lg-none">Remarks</label>
                          <b-form-textarea
                            v-model="item.remarks"
                            placeholder="Remarks"
                            class="mb-2 mb-lg-0"
                          />
                        </b-col>

                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index)"
                        />


                        <!-- Setting Item Form -->

                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add Item
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex"
                  order="2"
                  order-md="1"
                >

                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item"  v-for="(pay, index) in invoiceData.payments" :key="index">
                      <p class="invoice-total-title">
                        {{pay.method.label}}
                      </p>
                      <p class="invoice-total-amount">
                        ${{parseInt(pay.amount).toFixed(2)}}
                      </p>
                    </div>

                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        ${{payment_total.toFixed(2)}}
                      </p>
                    </div>


                    </div>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        ${{invoice_total.toFixed(2)}}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        ${{invoice.discount.toFixed(2)}}
                      </p>
                    </div>

                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        ${{invoice_total.toFixed(2)}}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.remarks" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="submit_invoice()"
            :disabled="invoice_total < 1"
          >
            Submit Invoice
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="back()"
          >
            Back
          </b-button>
        </b-card>

        <!-- Payment Method -->
        <div class="mt-2">
          <b-card>
          <b-form-group
            label="Accept Payment Via"
            label-for="payment-method"
          >
            <v-select
              v-model="selected_payment.method"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              input-id="payment-method"
              class="payment-selector"
              :clearable="false"
            />
          </b-form-group>
                          <b-form-input
                          v-model="selected_payment.amount"
                            placeholder="Payment Amount"
                            type="number"
                            class="mb-2"
                          />


                          <div v-if="selected_payment.method.value == 'STRIPE'">
                            <hr class="mt-1"/>
                            <b-form-group
                              label="Credit Card Number"
                              label-for="card"
                            >
                              <b-form-input
                                id="card"
                                v-mask="credit_card_format"
                                v-model="card.number"

                                trim
                              />
                            </b-form-group>


                          <b-form-group
                            label="Expiry Date (YYYY-MM)"
                            label-for="Expiry Date"
                          >
                            <b-form-input
                              id="expire"
                              v-mask="'####-##'"
                              v-model="card.expire"

                              trim
                            />


                          </b-form-group>

                         <b-form-group
                           label="CVC"
                           label-for="cvc"
                         >
                           <b-form-input
                             id="cvc"
                             v-mask="cvc_format"
                             v-model="card.cvc"
                             trim
                           />


                         </b-form-group>
                       </validation-provider>
                </div>
                <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-75"
              block
              @click="add_payment()"

              :disabled="selected_payment.amount < 1 || processing === true"
              >
              {{loading}}
              </b-button>
          <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-warning"
          class="mb-75"
          block
          @click="clear_payment()"
          >
          Clear Payment
          </b-button>
          <div v-for="(payment, index) in invoiceData.payments">
            {{payment.method.value}} - $ {{payment.amount}}
          </div>
          <hr/>
          <h4>Total : ${{payment_total.toFixed(2)}}</h4>
                          </b-card>
          <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BFormSpinbutton, BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'
import router from '@/router'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ToastificationContent,
    router,
    BFormSpinbutton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  mixins: [heightTransition],
  watch: {
    "card.number": {
      handler(newValue, oldValue) {
        console.log(newValue.substring(0,1))
        switch (newValue.substring(0,1)) {
          case "3":
            this.credit_card_format = '#### ###### #####'
            this.cvc_format = '####'
            break;
          default:
          this.credit_card_format = '#### #### #### ####'
            this.cvc_format = '###'
        }
      }
    }
  },
  data() {
    return {
      test: [],
      credit_card_format : '#### #### #### ####',
      cvc_format : '###',
      processing : false,
      itemFormBlankItem: { qty : 1},
      paymentMethods: [ {value:'CASH',label:'Cash'},{value:'VISA',label:'Visa'},{value:'MasterCard',label:'MasterCard'},{value:'FPS',label:'轉數快'},{value:'PAYME',label:'PayMe'} ],
      member:{
        zh_name:null,
        en_name:null
      },
      selected_payment : {
        method : {
          label : "Cash",
          value : "CASH"
        },
        amount : null
      },
      invoice: {
        subtotal : 0,
        discount : 0,
        total : 0
      },
      invoiceData:{
        items:[],
        payments:[],
        total_amount:0,
      },
      card : {
        number:null,
        expire:null,
        cvc:null,
        amount:0,
        member_id:null
      },
      paymentOptions:[],
      itemsOptions:[],
      invoiceData:{
        items:[],
        payments:[],
        total_amount:0,
      }
    }
  },
  mounted() {
    this.initTrHeight()
  },
  computed : {
    loading() {
      if (this.processing === true) {
        return "Loading..."
      } else {
        return "Add Payment"
      }
    },
    invoice_total() {
      let total = 0
      this.invoiceData.items.forEach (item => {
       // if (item.itemTitle.price && item.itemTitle.qty > 0 && item.itemTitle.price > 0) {
      //
       // }
       if (item.itemTitle) {
          total += item.itemTitle.price * item.qty
       }
       // console.log(item)
      })
      this.invoiceData.total_amount = total
      return total
    },
    payment_total() {
      let total = 0
      this.invoiceData.payments.forEach (item => {
        if (item.amount && item.amount > 0 ) {
          total += Number(item.amount)
        }
      })
      return total
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    this.get_member()
    this.get_product()
    this.get_payment_method()
    this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    submit_invoice() {
      console.log(JSON.stringify(this.invoiceData))
      this.$http.post(process.env.VUE_APP_API_BASE+'/member/'+ router.currentRoute.params.id +'/transaction/add/?center_id='+this.$store.getters['center/currentCenterId'] ,this.invoiceData)
      .then(res => {
              if (res.data.result === true) {
               this.$toast({
               component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Success',
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: res.data.message,
                  },
                })
              this.back()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                  variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })

    },

    async add_payment() {
      console.log(this.invoice_total - this.payment_total + " " + this.selected_payment.amount)
      if ( (this.invoice_total - this.payment_total  ) < this.selected_payment.amount ) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: '金額大於選擇項目',
          },
        })
        return
      }
      this.processing = true
      switch(this.selected_payment.method.value) {
        case "STRIPE":
        this.card.amount = this.selected_payment.amount
        this.card.member_id = router.currentRoute.params.id
        await this.$http.post(process.env.VUE_APP_API_BASE+'/payment_gateway/stripe/' + this.$store.getters['center/currentCenterId'] + '/', this.card)
          .then(res => {
            if (res.data.result === true) {
              this.$toast({
              component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
              this.selected_payment.stripe_id = res.data.stripe_id
              this.card.number = null
              this.card.expire = null
              this.card.cvc = null
              this.card.amount = 0
              this.add_payment_2()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-center',
                props: {
                  title: 'Error',
                  icon: 'XIcon',
                  variant: 'danger',
                  text: res.data.message,
                },
              },
              {
                timeout:999999,
              })
              this.show = false
              this.processing = false
            }
          })
        break;
        default:
          this.add_payment_2()
        break;

      }

    },
    add_payment_2() {
      this.invoiceData.payments.push(this.selected_payment)
      this.selected_payment = {
        method : {
          label : "Cash",
          value : "CASH",
          stripe_id : null
        },
        amount : null
      }
      this.processing = false
    },
    clear_payment() {
      let pass = true
      this.invoiceData.payments.forEach (item => {
        if (item.method.value == "STRIPE" ) {
          pass = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-center',
            props: {
              title: 'Error',
              icon: 'XIcon',
            variant: 'danger',
              text: "信用卡已經付款, 不能消除",
            },
          })
          return
        }
      })
      if (pass === true) {
          this.invoiceData.payments = [];
      }
    },
    back() {
      this.$router.back()
    },
    updateItemForm (index, val) {
    //  console.log(val)
    console.clear()
      this.invoiceData.items[index].itemTitle = val
      console.log(this.invoiceData)
    //  this.invoiceData.items[index].price = val.price
    //  this.invoiceData.items[index].qty = qty
    },
    get_member() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/member/'+ router.currentRoute.params.id+'/')
        .then(res => {
          this.member = res.data
        })
    },
    get_product() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/products/')
        .then(res => {
          this.itemsOptions = res.data
        })
    },
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden'
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))



      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        setTimeout(() => {
          this.$refs.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
